<template>
    <div>
        <div class="main-navbar">
            <div id="mainnav">
                <div class="toggle-nav" :class="leftSidebarVal ? 'toggle-button' : ''" @click="openmobilenav=true">
                    <i class="fa fa-bars sidebar-bar"></i>
                </div>
                <ul class="nav-menu" :class="{ opennav: openmobilenav }">
                    <li class="back-btn">
                        <div class="mobile-back text-right">
                            <span @click="openmobilenav=false">Retour</span>
                            <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
                        </div>
                    </li>
                    <li v-for="(menuItem, index) in menuList" :key="index">
                        <!--<a href="#" class="nav-link" @click="setActive(menuItem.title)">-->
                            <router-link :to="{ path: menuItem.path}" @click="setActive(menuItem.title)">
                                {{menuItem.title}}
                            </router-link>
                        <!--</a>-->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Menu from '../../data/menu.json'

    export default {
        name: "navbar",

        props: ['leftSidebarVal'],
        data() {
            return {
                menuList : Menu.data,
                openmobilenav: false,
                subnav: false,
                activeItem: 'Accueil',
                activeChildItem: 'fashion 1',
                activemegaChild: 'portfolio'
            }
        },

        computed: {
            // ...mapState({
            //     menulist: state => state.menu.data
            // })
        },
        methods: {
            mobilenav: function () {
                this.openmobilenav = !this.openmobilenav
            },
            isActive: function (menuItem) {
                return this.activeItem === menuItem
            },
            setActive: function (menuItem) {
                if (this.activeItem === menuItem) {
                    this.activeItem = ''
                } else {
                    this.activeItem = menuItem
                }
            },
            isActiveChild: function (menuChildItem) {
                return this.activeChildItem === menuChildItem
            },
            setActiveChild: function (menuChildItem) {
                console.log(menuChildItem)
                if (this.activeChildItem === menuChildItem) {
                    this.activeChildItem = ''
                } else {
                    this.activeChildItem = menuChildItem
                }
            },
            isActivesubmega: function (megaChildItem) {
                return this.activemegaChild === megaChildItem
            },
            setActivesubmega: function (megaChildItem) {
                if (this.activemegaChild === megaChildItem) {
                    this.activemegaChild = ''
                } else {
                    this.activemegaChild = megaChildItem
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .toggle-nav {
        &.toggle-button {
            z-index: 1;
        }
    }
</style>
