<template>
    <div>
        <div class="title1 section-t-space">
            <h4>{{subtitle}}</h4>
            <h2 class="title-inner1">{{title}}</h2>
        </div>
        <br/>
        <section class="section-b-space p-t-0 ratio_asos">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="theme-tab">
                            <b-tabs content-class="mt-3">
                                <b-tab
                                        :title="collection.title"
                                        v-for="(collection,index) in specialProduct"
                                        :key="index"
                                >
                                    <div class="no-slider row">
                                        <div
                                                class="product-box"
                                                v-for="(product,index) in getCategoryProduct(collection)"
                                                :key="index"
                                        >
                                            <productBox
                                                    @opencartmodel="showCartModal"
                                                    @showCompareModal="showcomparemodal"
                                                    @openquickview="showquickview"
                                                    @showalert="alert"
                                                    @alertseconds="alert"
                                                    :product="product"
                                                    :index="index"
                                            />
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <b-alert
                :show="dismissCountDown"
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="alert"
        >
            <p>Product Is successfully added to your wishlist.</p>
        </b-alert>
    </div>
</template>
<script type="text/javascript">
  import productBox from "../../../components/product-box/product-box";
  import { mapGetters } from "vuex";

  export default {
    props: [],
    components: {
      productBox
    },
    data() {
      return {
        title: "Produits Speciaux",
        subtitle: "Produit Exclusifs",
        showCart: false,
        showquickviewmodel: false,
        showcomapreModal: false,
        quickviewproduct: {},
        comapreproduct: {},
        cartproduct: {},
        dismissSecs: 5,
        dismissCountDown: 0,

        specialProduct: [
          {
            title: "Nouveau Produits",
            attribute: "new",
            attributeValue: true
          },
          {
            title: "Produits Spéciaux",
            attribute: "featured",
            attributeValue: true
          }
        ]
      };
    },
    methods: {
      getCategoryProduct(collection) {
        return this.products.filter((item) => {
          if (item[collection.attribute]) {
            return item;
          }
        });
      },
      alert(item) {
        this.dismissCountDown = item;
      },
      showCartModal(item, productData) {
        this.showCart = item;
        this.cartproduct = productData;
        this.$emit("openCart", this.showCart, this.cartproduct);
      },
      showquickview(item, productData) {
        this.showquickviewmodel = item;
        this.quickviewproduct = productData;
        this.$emit("openQuickview", this.showquickviewmodel, this.quickviewproduct);
      },
      showcomparemodal(item, productData) {
        this.showcomapreModal = item;
        this.comapreproduct = productData;
        this.$emit("openCompare", this.showcomapreModal, this.comapreproduct);
      }
    },

    computed: {
      ...mapGetters("category", ["categories"]),
      ...mapGetters("products", ["products"])
    }
  };
</script>
