<template xmlns:v-swiper="http://www.w3.org/1999/xhtml">
    <div>
        <!-- <h2 class="title">{{ $t('home.title') }}</h2> -->
        <!-- <h2 class="subtitle">{{ $t('home.introduction') }}</h2> -->
        <!-- Home slider -->
        <section class="p-0">
            <div class="slide-1 home-slider">
                <div v-swiper:mySwiper="swiperOption">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in items" :key="index">
                            <div
                                    class="home text-center"
                                    :class="item.alignclass"
                                    v-bind:style="{ 'background-image': 'url(' + item.original_url + ')' }"
                            >
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <div class="slider-contain">
                                                <div>
                                                    <!--<h4>{{ item.title }}</h4>-->
                                                    <!--<h1>{{ item.subtitle }}</h1>-->
                                                    <!--<router-link :to="{ path: '/produits'}" class="btn btn-solid">Acheter maintenant</router-link>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </div>
            </div>
        </section>
        <!-- Home slider end -->
    </div>
</template>

<script>
  export default {
    name: "slider",

    props: [
      "items"
    ],

    data() {
      return {
        swiperOption: {
          loop: true,
          effect: 'fade',
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          keyboard: {
            enabled: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        }

      };
    },
    methods: {}

  };
</script>

<style scoped>

</style>
