<template>
    <div>
        <AppHeader/>
        <Slider :items="items"/>
        <ProductSlider :products="products" @openQuickview="showQuickview" @openCompare="showCoampre"
                       @openCart="showCart"/>
        <Banner/>
        <ProductTab :products="products" :category="category" @openQuickview="showQuickview" @openCompare="showCoampre"
                    @openCart="showCart"/>

        <!--<Service/>-->
        <AppFooter/>
        <quickviewModel :openModal="showquickviewmodel" :productData="quickviewproduct"/>
    </div>
</template>

<script>
  import AppHeader from "../../components/header/appHeader";
  import AppFooter from "../../components/footer/appFooter";

  import quickviewModel from "../../components/widgets/quickview";

  import Slider from "./components/slider.vue";
  import ProductSlider from "./components/product_slider.vue";
  import ProductTab from "./components/product_tab.vue";
  // import Service from "./components/services";
  import Banner from "./components/banner.vue";
  import { mapActions, mapGetters } from "vuex";
  import productMixin from "../../mixins/productMixins";
  import bannerMixins from "../../mixins/bannerMixins";

  export default {
    name: "Ascension.vue",

    mixins: [productMixin, bannerMixins],

    components: {
      AppHeader,
      Slider,
      ProductSlider,
      Banner,
      ProductTab,
      AppFooter,
      // Service,
      quickviewModel
    },

    methods: {
      ...mapActions({
        getCustomerDetails: 'customer/fetchCustomers'
      }),

      closeCartModal(item) {
        this.showcartmodal = item;
      },
    },

    computed: {
      ...mapGetters("products", ["products"]),
      ...mapGetters('auth', ['isLogged', 'user']),
    },

    watch:{
      isLogged:{
        immediate:true,
        handler:function(val){
          if(val){
            this.getCustomerDetails(this.user.customer_id)
          }
        }
      }
    },

    mounted() {
      this.$store.dispatch("products/fetchProducts");
      this.$store.dispatch("category/fetchCategories");
      this.getMedias('homeBanner')
    }
  };
</script>

<style scoped>

</style>
