<template>
  <div>
    <div class="icon-nav">
      <ul>
        <li class="onhover-div mobile-search">
          <div>
            <img
                alt
                :src='"@/assets/images/icon/layout4/search.png"'
                @click="openSearch()"
                class="img-fluid"
            >
            <i class="ti-search" @click="openSearch()"></i>
          </div>
          <div id="search-overlay" class="search-overlay" :class="{ opensearch:search }">
            <div>
              <span class="closebtn" @click="closeSearch()" title="Close Overlay">×</span>
              <div class="overlay-content">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-12">
                      <form>
                        <div class="form-group mb-0">
                          <input
                              type="text"
                              class="form-control"
                              v-model="searchString"
                              @keyup="searchProduct"
                              placeholder="Rechercher Produit..."
                          >
                        </div>
                        <button type="submit" class="btn btn-primary">
                          <i class="fa fa-search"></i>
                        </button>
                      </form>
                      <ul class="search-results" v-if="searchItems.length">
                        <li v-for="(product,index) in searchItems" :key="index"
                            class="product-box">
                          <div class="img-wrapper">
                            <img
                                :src='getImgUrl(product.images[0].src)'
                                class="img-fluid bg-img"
                                :key="index"
                            />
                          </div>
                          <div class="product-detail">
                            <router-link :to="{ path: '/product/sidebar/'+product.id}">
                              <h6>{{ product.title }}</h6>
                            </router-link>
                            <h4>{{ product.price * curr.curr | currency(curr.symbol) }}</h4>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="onhover-div mobile-cart">
          <div>
            <img alt :src='"@/assets/images/icon/layout4/cart.png"' class="img-fluid">
            <i class="ti-shopping-cart"></i>
            <span class="cart_qty_cls" v-if="!cart || cart.length==0">0</span>
            <span class="cart_qty_cls" v-else>{{ cart.length }}</span>
          </div>
          <ul class="show-div shopping-cart" v-if="!cart || cart.length==0">
            <li>Votre panier est vide.</li>
          </ul>
          <ul class="show-div shopping-cart" v-if="cart.length">
            <li v-for="(item,index) in cart" :key="index">
              <div class="media">
                <router-link :to="{ path: '/produit/'+item.id}">
                  <img alt class="mr-3" :src='item.images.length > 0 ? item.images[0].original_url : ""'>
                </router-link>
                <div class="media-body">
                  <router-link :to="{ path: '/produit/'+item.id}">
                    <h4>{{ item.name }}</h4>
                  </router-link>
                  <h4>
                    <span>{{ item.quantity }} x {{ item.currency.symbol }} {{ item.price | numeral('0,0.00') }}</span>
                  </h4>
                </div>
              </div>
              <div class="close-circle">
                <a href="#" @click='removeCartItem(item)'>
                  <i class="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </li>
            <li>
              <div class="total">
                <h5>
                  Sous-total :
                  <span>{{ cartTotal | numeral('0,0.00') }}</span>
                </h5>
              </div>
            </li>
            <li>
              <div class="buttons">
                <router-link :to="{ path: '/dashboard/panier'}" :class="'view-cart'">
                  Voir Panier
                </router-link>
                <router-link :to="{ path: '/checkout'}" :class="'checkout'">
                  Commander
                </router-link>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {EventBus} from "../../utils/utils";

export default {
  name: "header-widgets",

  data() {
    return {
      currencyChange: {},
      search: false,
      searchString: ''
    }
  },
  computed: {
    ...mapState({
      searchItems: state => state.products.searchProduct
    }),
    ...mapGetters({
      cart: 'cart/cartItems',
      cartTotal: 'cart/cartTotalAmount'
    })
  },
  methods: {
    ...mapActions({
      listProducts: "products/fetchProducts"
    }),

    openSearch() {
      if(this.$route.name !== "Produits"){
        this.$router.push({ name: "Produits" });
      }
      this.search = true
    },
    closeSearch() {
      this.search = false
    },
    searchProduct() {
      EventBus.$emit("searchProductEvent", this.searchString);
    },
    removeCartItem: function (product) {
      this.$store.dispatch('cart/removeCartItem', product)
    },
    updateCurrency: function (currency, currSymbol) {
      this.currencyChange = {curr: currency, symbol: currSymbol}
      this.$store.dispatch('products/changeCurrency', this.currencyChange)
    }
  }
}
</script>

<style scoped>

</style>
