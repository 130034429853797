<template>
    <div>
        <footer class="footer-light">
            <div class="light-layout">
                <div class="container">
                    <!--          <section class="small-section border-section border-top-0">-->
                    <!--            <div class="row">-->
                    <!--              <div class="col-lg-6">-->
                    <!--                <div class="subscribe">-->
                    <!--                  <div>-->
                    <!--                    <h4>KNOW IT ALL FIRST!</h4>-->
                    <!--                    <p>Never Miss Anything From Multikart By Signing Up To Our Newsletter.</p>-->
                    <!--                  </div>-->
                    <!--                </div>-->
                    <!--              </div>-->
                    <!--              <div class="col-lg-6">-->
                    <!--                <form-->
                    <!--                  class="form-inline subscribe-form auth-form needs-validation"-->
                    <!--                  method="post"-->
                    <!--                  id="mc-embedded-subscribe-form"-->
                    <!--                  name="mc-embedded-subscribe-form"-->
                    <!--                  target="_blank"-->
                    <!--                >-->
                    <!--                  <div class="form-group mx-sm-3">-->
                    <!--                    <input-->
                    <!--                      type="text"-->
                    <!--                      class="form-control"-->
                    <!--                      name="EMAIL"-->
                    <!--                      id="mce-EMAIL"-->
                    <!--                      placeholder="Enter your email"-->
                    <!--                      required="required"-->
                    <!--                    />-->
                    <!--                  </div>-->
                    <!--                  <button type="submit" class="btn btn-solid" id="mc-submit">subscribe</button>-->
                    <!--                </form>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </section>-->
                </div>
            </div>
            <section class="section-b-space light-layout">
                <div class="container">
                    <div class="row footer-theme partition-f">
                        <div class="col-lg-4 col-md-6">
                            <!--                            <div class="footer-title footer-mobile-title">
                                              <h4>about</h4>
                                          </div>-->
                            <div class="footer-contant">
                                <div class="footer-logo">
                                    <!--                  <img :src="'@/assets/images/logo.png'" alt="logo" width="179"/>-->
                                    <img :src='"@/assets/images/logo.png"' class="img-fluid" alt width="120"
                                         height="auto"/>
                                </div>
                                <p>{{title}} {{ intro }}</p>
                                <div class="footer-social">
                                    <ul>
                                        <li>
                                            <a href="https://m.facebook.com/100082532005603/" target="_blank">
                                                <i class="fa fa-facebook" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <!--                    <li>
                                                              <a href="#">
                                                                <i class="fa fa-google-plus" aria-hidden="true"></i>
                                                              </a>
                                                            </li>-->
                                        <li>
                                            <a href="https://twitter.com/AscensionGroup1?t=n6anP0Coa6N423ucLd9Xnw&s=09"
                                               target="_blank">
                                                <i class="fa fa-twitter" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com/ascensiongrouphaiti?igshid=YmMyMTA2M2Y="
                                               target="_blank">
                                                <i class="fa fa-instagram" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://youtube.com/channel/UCqKb3yyUhwY5RNYGw6CJuUw">
                                                <i class="fa fa-youtube" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col offset-xl-1" style="margin-bottom: 10px">
                            <div class="sub-title">
                                <div class="footer-title">
                                    <h4>Mon compte</h4>
                                </div>
                                <div class="footer-contant">
                                    <ul>
                                        <li v-if="!isLogged">
                                            <router-link :to="{ path: '/login' }">
                                                Connexion
                                            </router-link>
                                        </li>
                                        <li v-if="!isLogged">
                                            <router-link :to="{ path: '/register' }">
                                                Inscription
                                            </router-link>
                                        </li>
                                        <li v-if="isLogged">
                                            <router-link :to="{ path: '/dashboard' }">
                                                Tableau de bord
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{ path: '/aide' }"> Aide</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col" style="margin-bottom: 10px">
                            <div class="sub-title">
                                <div class="footer-title">
                                    <h4>Pourquoi nous</h4>
                                </div>
                                <div class="footer-contant">
                                    <ul>
                                        <li>
                                            <router-link :to="{ path: '/contact' }">
                                                Contact
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{ path: '/compagnie' }">
                                                Compagnie
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{ path: '/evenement' }">
                                                Evènement
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{ path: '/opportunite' }">
                                                Opportunités
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="sub-title">
                                <div class="footer-title">
                                    <h4>Information</h4>
                                </div>
                                <div class="footer-contant">
                                    <ul class="contact-list">
                                        <li>
                                            <i class="fa fa-map-marker"></i>
                                            {{ title }} <br/>
                                            {{ addresse }}
                                        </li>
                                        <li>
                                            <i class="fa fa-phone"></i>Appelez-nous: <br/>
                                            {{ telephone }}
                                        </li>
                                        <li >
                                            <i class="fa fa-envelope-o"></i>Ecrivez-nous:
                                            <a style="text-transform: lowercase" :mailto="contact_email">{{ contact_email }}</a>
                                        </li>
                                        <!--<li>-->
                                        <!--<i class="fa fa-fax"></i>Fax: 123456-->
                                        <!--</li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="sub-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-md-6 col-sm-12">
                            <div class="footer-end">
                                <p>
                                    <i class="fa fa-copyright" aria-hidden="true"></i>
                                    {{ "2022" }} {{ title }}
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 col-sm-12">
                            <div class="payment-card-bottom">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <img :src="'@/assets/images/icon/moncash.png'" alt/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "appFooter",

    computed: {
      ...mapGetters("auth", ["isLogged", "user"]),
      ...mapGetters("setting", [
        "title",
        "addresse",
        "telephone",
        "contact_email",
        "intro"
      ])
    },

    mounted() {
      this.$store.dispatch("setting/fetchSettings");
    }
  };
</script>

<style scoped></style>
