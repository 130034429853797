<template>
    <div>
        <div class="title1 section-t-space">
            <h4>{{subtitle}}</h4>
            <h2 class="title-inner1">{{title}}</h2>
        </div>
<!--        <div class="container">-->
<!--            <div class="row">-->
<!--                <div class="col-lg-6 offset-lg-3">-->
<!--                    <div class="product-para">-->
<!--                        <p class="text-center">{{description}}</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <section class="section-b-space p-t-0 ratio_asos">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div v-swiper:mySwiper="swiperOption">
                            <div class="swiper-wrapper">
                                <div
                                        class="swiper-slide"
                                        v-for="(product,index) in products"
                                        :key="index"
                                >
                                    <div class="product-box">
                                        <productBox
                                                @opencartmodel="showCartModal"
                                                @showCompareModal="showcomparemodal"
                                                @openquickview="showquickview"
                                                @showalert="alert"
                                                @alertseconds="alert"
                                                :product="product"
                                                :index="index"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <b-alert
                :show="dismissCountDown"
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="alert"
        >
            <p>Product Is successfully added to your wishlist.</p>
        </b-alert>
    </div>
</template>

<script>
    import productBox from '../../../components/product-box/product-box'
    import axios from 'axios'

    export default {
        name: "product_slider",

        props: [],
        components: {
            productBox
        },

        data() {
            return {
                products: [],

                title: 'top collection',
                subtitle: 'offre speciale',
                showCart: false,
                showquickviewmodel: false,
                showcomapreModal: false,
                quickviewproduct: {},
                comapreproduct: {},
                cartproduct: {},
                dismissSecs: 5,
                dismissCountDown: 0,
                description:
                    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
                swiperOption: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                    freeMode: false,
                    breakpoints: {
                        1199: {
                            slidesPerView: 4,
                            spaceBetween: 20
                        },
                        991: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        },
                        420: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                    }
                }
            }
        },
        methods: {
            getFeaturedProducts(){
                let self = this
                axios.get('product/specific')
                .then(({data}) => {
                    console.log("DATA", data)
                    if(data.success){
                        self.products = data.data
                    }
                })
            },

            alert(item) {
                this.dismissCountDown = item
            },
            showCartModal(item, productData) {
                this.showCart = item
                this.cartproduct = productData
                this.$emit('openCart', this.showCart, this.cartproduct)
            },
            showquickview(item, productData) {
                this.showquickviewmodel = item
                this.quickviewproduct = productData
                this.$emit('openQuickview', this.showquickviewmodel, this.quickviewproduct)
            },
            showcomparemodal(item, productData) {
                this.showcomapreModal = item
                this.comapreproduct = productData
                this.$emit('openCompare', this.showcomapreModal, this.comapreproduct)
            }
        },

        mounted() {
            this.getFeaturedProducts();
        }
    }
</script>

<style scoped>

</style>
