<template>
    <div>
        <section class="p-0">
            <div
                    class="full-banner parallax text-center p-left"
                    v-bind:style="{ 'background-image': `url(${parallax.length > 0 ? parallax[0].original_url : ''})` }"
            >
                <img :src="parallax.length > 0 ? parallax[0].original_url : ''" alt class="bg-img d-none" />
            <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="banner-contain">
                                <h4>{{text}}</h4>
                                <h2>{{title}}</h2>
                                <h3>{{subtitle}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script type="text/javascript">
    import bannerMixin from "../../../mixins/bannerMixins";

    export default {
        name: 'banner.vue',

        mixins: [bannerMixin],

        data() {
            return {
                imagepath: require('@/assets/images/parallax/1.jpg'),
                title: 'Rejoignez-nous!',
                subtitle: '',
                text: 'Pour une vie de confort'
            }
        },

        mounted() {
            this.getMedias('homeParallax')
        }
    }
</script>
