import { render, staticRenderFns } from "./slider.vue?vue&type=template&id=10b609dc&scoped=true&xmlns%3Av-swiper=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml"
import script from "./slider.vue?vue&type=script&lang=js"
export * from "./slider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b609dc",
  null
  
)

export default component.exports