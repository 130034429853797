

const generalMixin = {
  data(){
    return {
      customer: '',
      customerCode: ''
    }
  },

  methods: {
    eventMedia(medialists){
      if(medialists.length > 0){
        return medialists[0].original_ur
      }else{
        return "../../assets/images/mega.jpg";
      }
    }
  },

  watch: {
    user: {
      immediate:true,
      handler: function (val) {
        if(val){
          this.customer = val.fname+' '+val.lname
          this.customerCode = val.code
        }
      }
    }
  }
}

export default generalMixin;
