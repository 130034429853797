import axios from "axios";

const bannerMixin = {
  data() {
    return {
      items: [],
      parallax: "",
      opportunityBanner: "",
      productTopBanner: "",
      productSideBanner: "",
      eventBanner: [],
      documentationMedias: []
    };
  },

  methods: {
    getMedias(destination) {
      let self = this;
      return new Promise((resolve, reject) => {
        axios.get("images", {
          params: {
            destination: destination
          }
        })
          .then(data => {
            switch (destination) {
              case "homeParallax":
                self.parallax = data.data.data ? data.data.data : "";
                break;
              case "opportunityBanner":
                self.opportunityBanner = data.data.data.length > 0 ? data.data.data[0] : "";
                break;
              case "collectionTopBanner":
                self.productTopBanner = data.data.data.length > 0 ? data.data.data[0] : "";
                break;
              case "collectionSideBanner":
                self.productSideBanner = data.data.data.length > 0 ? data.data.data[0] : "";
                break;
              case "eventBanner":
                self.eventBanner = data.data.data ? data.data.data : "";
                break;
              case "documentationMedia":
                self.documentationMedias = data.data.data ? data.data.data : "";
                break;
              default:
                self.items = data.data.data ? data.data.data : [];
            }
            resolve(data.data.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};

export default bannerMixin;
