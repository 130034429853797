

const productMixin = {
    data(){
        return{
            showquickviewmodel: false,
            showcomparemodal: false,
            showcartmodal: false,
            quickviewproduct: {},
            comapreproduct: {},
            cartproduct: {},

            filterQuery: {
                categories: []
            },
        }
    },

    methods: {
        determineDicount(productPrice, discount, discoutnType){
            let discountValue = 0
            if(discoutnType === 'PERCENT'){
                discountValue = productPrice * (discount/100)
            }else{
                discountValue = discount
            }
            return discountValue
        },

        showQuickview(item, productData) {
            this.showquickviewmodel = item
            this.quickviewproduct = productData
        },
        showCoampre(item, productData) {
            this.showcomparemodal = item
            this.comapreproduct = productData
        },
        closeCompareModal(item) {
            this.showcomparemodal = item
        },


        showCart(item, productData) {
            this.showcartmodal = item
            this.cartproduct = productData
        },
    }
}

export default  productMixin
