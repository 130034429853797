<template>
    <div>
        <div class="img-wrapper">
            <div class="lable-block">
                <span class="lable3" style="top: 27px !important;"
                      v-if="product.show_point">{{ product.point }} Pts</span>
            </div>
            <div class="front" style="max-height: 37vh;min-height: 37vh; display:flex; align-items: center;
  justify-content: center;">
                <router-link :to="{ path: '/produit/'+product.slug}">
                    <img
                            :src='product.images[0].original_url'
                            :id="product.id"
                            class="img-fluid"
                            :alt="product.name"
                            :key="index"
                            ref="productImage"
                    />
                </router-link>
            </div>
<!--            <ul class="product-thumb-list">-->
<!--                <li-->
<!--                        class="grid_thumb_img"-->
<!--                        :class="{active: imageSrc === image.src}"-->
<!--                        v-for="(image,index) in product.images"-->
<!--                        :key="index"-->
<!--                        @click="productVariantChange(image.src)"-->
<!--                >-->
<!--                    <a href="javascript:void(0);">-->
<!--                        <img src=""/>-->
<!--                    </a>-->
<!--                </li>-->
<!--            </ul>-->
            <!--<div class="cart-info cart-wrap">-->
                <!--<a href="javascript:void(0)" title="Quick View" @click="showQuickview(product)" v-b-modal.modal-lg-->
                   <!--variant="primary">-->
                    <!--<i class="ti-search" aria-hidden="true"></i>-->
                <!--</a>-->
            <!--</div>-->
        </div>
        <div class="product-detail">
<!--            <div class="rating">-->
<!--                <star-rating v-model="rating" :read-only="true" :show-rating="false" :star-size=16></star-rating>-->
<!--            </div>-->
            <router-link :to="{ path: '/produit/'+product.slug}">
                <h6>{{ product.name }}</h6>
            </router-link>
            <p :html="product.description"></p>
            <h4 v-if="product.show_price">
                {{ product.currency.symbol }} {{ product.price | numeral("0,0.00") }}
                <button data-toggle="modal" data-target="#addtocart" title="Add to cart" @click="addToCart(product)"
                        v-b-modal.modal-cart variant="primary"
                        style="border: none;background: transparent;color: var(&#45;&#45;theme-deafult);float: right" v-if="product.type === 'simple'">
                    <i class="ti-shopping-cart"></i>
                </button>
            </h4>
<!--            <h4 v-else>{{ product.price }}</h4>-->
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapState } from "vuex";
  // import StarRating from "vue-star-rating";
  // import holder from 'holderjs';

  export default {
    name: "product-box",

    props: {
      product: {
        type: Object,
        required: true
      },
      index: {
        required: true
      }
    },

    components: {
      // StarRating
    },

    data() {
      return {
        imageSrc: "",
        quickviewProduct: {},
        compareProduct: {},
        cartProduct: {},
        showquickview: false,
        showCompareModal: false,
        cartval: false,
        variants: {
          productId: "",
          image: ""
        },
        dismissSecs: 5,
        dismissCountDown: 0,

        rating: 0
      };
    },

    watch: {
      product: {
        immediate: true,
        handler(e) {
          if (e.ratings) {
            this.rating = e.ratings;
          } else {
            this.rating = 0;
          }
        }
      }
    },

    computed: {
      ...mapState({
        productslist: state => state.products.productslist
      }),
      ...mapGetters({
        curr: "products/changeCurrency"
      })
    },

    methods: {
      getImgUrl(path) {
        console.log(path);
        // return require('@/assets/images/' + path)
        return "../../assets/images/collection/1.jpg";
      },
      addToCart: function(product, qty) {
        product.quantity = qty || 1;
        this.$store.dispatch("cart/addToCart", product);
      },
      /*addToCart: function (product) {
        if(product.type !== 'simple'){
          this.$router.push('/produit/'+product.id)
        }else{
          this.cartval = true
          this.cartProduct = product
         /!* this.$emit('opencartmodel', this.cartval, this.cartProduct)*!/
          this.$store.dispatch('cart/addToCart', product)
        }
      },*/
      addToWishlist: function(product) {
        this.dismissCountDown = this.dismissSecs;
        this.$emit("showalert", this.dismissCountDown);
        this.$store.dispatch("products/addToWishlist", product);
      },
      showQuickview: function(productData) {
        this.showquickview = true;
        this.quickviewProduct = productData;
        this.$emit("openquickview", this.showquickview, this.quickviewProduct);
      },
      addToCompare: function(product) {
        this.showCompareModal = true;
        this.compareProduct = product;
        this.$emit("showCompareModal", this.showCompareModal, this.compareProduct);
        this.$store.dispatch("products/addToCompare", product);
      },
      Color(variants) {
        const uniqColor = [];
        for (let i = 0; i < Object.keys(variants).length; i++) {
          if (uniqColor.indexOf(variants[i].color) === -1) {
            uniqColor.push(variants[i].color);
          }
        }
        return uniqColor;
      },
      productColorchange(color, product) {
        product.variants.map((item) => {
          if (item.color === color) {
            product.images.map((img) => {
              if (img.image_id === item.image_id) {
                this.imageSrc = img.src;
              }
            });
          }
        });
      },
      productVariantChange(imgsrc) {
        console.log("I am calll");
        this.imageSrc = imgsrc;
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
        this.$emit("alertseconds", this.dismissCountDown);
      },
      discountedPrice(product) {
        const price = product.price - (product.price * product.discount / 100);
        return price;
      }
    }
  };
</script>

<style scoped>

</style>
