<template>
    <div>
        <header>
            <div class="mobile-fix-option"></div>
            <TopBar/>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="main-menu">
                            <div class="menu-left">
                                <div class="brand-logo">
                                    <router-link :to="{ path: '/'}">
                                        <img :src='"@/assets/images/logo.png"' class="img-fluid" alt width="120"
                                             height="auto"/>
                                    </router-link>
                                </div>
                            </div>
                            <div class="menu-right pull-right">
                                <Nav/>
                                <HeaderWidgets/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
    import TopBar from '../widgets/topbar'
    import Nav from "../widgets/navbar"
    import HeaderWidgets from '../widgets/header-widgets'
    import {mapGetters} from "vuex";

    export default {
        name: "appHeader",

        components: {
            TopBar,
            Nav,
            HeaderWidgets
        },

        computed: {
            ...mapGetters('auth', ['isLogged']),

        }
    }
</script>

<style scoped>

</style>
