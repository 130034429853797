<template xmlns:v-swiper="http://www.w3.org/1999/xhtml">
    <div>
        <b-modal
                id="modal-lg"
                size="lg"
                centered
                :title="productData.name"
                :hide-footer="true"
                v-if="openModal"
        >
            <div class="row quickview-modal">
                <div class="col-lg-6 col-xs-12">
                    <div class="quick-view-img">
                        <div v-swiper:mySwiper="swiperOption" v-if="productData.type !== 'simple'">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(imag,index) in selectedVariant.images" :key="index">
                                    <img
                                            :src="imag.original_url"
                                            :id="imag.id"
                                            class="img-fluid bg-img"
                                            :alt="selectedVariant.name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-swiper:mySwiper="swiperOption" v-else>
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(imag,index) in productData.images" :key="index">
                                    <img
                                            :src="imag.original_url"
                                            :id="imag.id"
                                            class="img-fluid bg-img"
                                            :alt="productData.name"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ productData.name }}</h2>
                        <h3 v-if="productData.type !== 'simple'">{{ selectedVariant.name }}</h3>
                        <h4 v-if="productData.show_price">
                                                <span class="text-success">
                                                    {{ productData.type!=="simple" ? selectedVariant.currency.symbol :  productData.currency.symbol }}
                                                    {{ productData.type!=="simple" ? selectedVariant.discount_value :  productData.discount_value | numeral("0,0.00") }} CASHBACK</span>
                        </h4>
                        <h3 v-if="productData.show_price">
                            {{ productData.type!=="simple" ? selectedVariant.currency.symbol :
                            productData.currency.symbol }}
                            {{ productData.type!=="simple" ? selectedVariant.price : productData.price |
                            numeral("0,0.00")}}
                            <br/>
                            <small class="text-muted" v-if="productData.show_point">{{
                                productData.type!=="simple" ? selectedVariant.point : productData.point |
                                numeral("0,0.00")}} Pts
                            </small>
                        </h3>
                        <vue-horizontal class="horizontal" snap="none" v-if="productData.type !=='simple'">
                            <div class="item"
                                 v-for="(variant,variantIndex) in productData.variants"
                                 :key="variantIndex">
                                <div class="ring"
                                     :class="variantIndex === selectedVariantIndex ? 'active' : ''"
                                     @click="selectVariant(variant,variantIndex)">
                                    <div class="avatar"
                                         :style="{background: `url(${variant.images.length > 0 ? variant.images[0].original_url : (productData.images.length > 0 ? productData.images[0].original_url : '')})`}">
                                    </div>
                                </div>
                            </div>
                        </vue-horizontal>
                        <div class="border-product">
                            <h6 class="product-title">Details Produit</h6>
                            <p v-html="productData.description+'....'"/>
                        </div>
                        <div class="product-buttons">
                            <a href="javascript:void(0)"
                               @click="addToCart(productData.type !=='simple' ? selectedVariant : productData)"
                               class="btn btn-solid">Au Panier</a>
                            <router-link :to="{ path: '/produit/'+productData.id}" class="btn btn-solid">Details
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
  import VueHorizontal from "vue-horizontal";
  import { mapGetters } from "vuex";

  export default {
    name: "quickview",

    components: {
      VueHorizontal
    },

    props: ["openModal", "productData"],
    data() {
      return {
        selectedVariant: "",
        selectedVariantIndex: 0,


        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 20,
          freeMode: true
        }
      };
    },
    computed: {
      ...mapGetters({
        curr: "products/changeCurrency"
      })
    },
    methods: {
      // add to cart
      addToCart: function(product) {
        this.$store.dispatch("cart/addToCart", product);
      },

      selectVariant(variant, idx) {
        this.selectedVariantIndex = idx;
        this.selectedVariant = variant
      }
    },

    watch: {
      productData: {
        immediate: true,
        handler: function(val) {
          console.log(val)
          /*if (val.type !== "simple") {
            this.selectedVariant = val.variants[0];
          }*/
        }
      }
    }
  };
</script>

<style scoped>
    .item {
        padding: 14px 6px;
    }

    .item:first-child {
        padding-left: 21px;
    }

    .item:last-child {
        padding-right: 21px;
    }

    .ring {
        width: 64px;
        height: 64px;
        border-radius: 32px;

        border: 3px solid transparent;
        background: #e2e8f0;

        cursor: pointer;
        transition: All 0.3s ease;
        overflow: hidden;
    }

    .ring:hover, .ring.active {
        /* This is a very simple trick to animation a ring of colors, you should go find a library for this*/
        transform: rotate(9deg) scale(1.05) translate(1px);
        animation: colors 1s ease infinite;
        background-size: 200% 200%;
        background-color: #663dff;
        border: 4px solid transparent;
        animation-direction: alternate;
        background-image: linear-gradient(319deg, #7d5fee 0%, #b72bff 33%, #ff2eb0 66%, #7eee40 100%);
    }

    @keyframes colors {
        0% {
            background-position: 10% 0
        }
        100% {
            background-position: 91% 100%
        }
    }

    .avatar {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        height: 100%;
        width: 100%;
    }

     /*You can add your own button or you could just, */
     /*Override default button design to make it smaller. */
    .horizontal >>> .v-hl-container {
        scroll-padding-left: 16px;
        scroll-padding-right: 16px;
    }
</style>
