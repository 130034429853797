<template>
  <div class="top-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact" v-if="isLogged">
            <ul>
              <li>{{ customer }}</li>
              <li>
                <i class="fa fa-qrcode" aria-hidden="true"></i
                >{{ customerCode }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="mobile-dashboard" v-if="isLogged">
              <router-link :to="{ path: '/dashboard' }">
                <i class="ti-dashboard" aria-hidden="true"></i>
                Tableau de Bord
              </router-link>
            </li>
            <li class="mobile-account" v-if="!isLogged">
              <router-link :to="{ path: '/login' }">
                <i class="ti-user" aria-hidden="true"></i>
                Connexion
              </router-link>
            </li>
            <li class="mobile-logout" v-if="isLogged" @click="logout">
              <i class="ti-power-off" aria-hidden="true"></i>
              Déconnexion
            </li>
            <li class="mobile-plus" v-if="!isLogged">
              <router-link :to="{ path: '/register' }">
                <i class="ti-plus" aria-hidden="true"></i>
                Inscription
              </router-link>
            </li>
            <li class="mobile-help">
              <router-link :to="{ path: '/aide' }">
                <i class="ti-help" aria-hidden="true"></i>
                Aide
              </router-link>
            </li>
            <li class="mobile-email">
              <router-link :to="{ path: '/contact' }">
                <i class="ti-email" aria-hidden="true"></i>
                Contact
              </router-link>
            </li>
          </ul>
          <!--                    <ul class="header-dropdown">
                                            </ul>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import generalMixin from "../../mixins/generalMixin";

export default {
  name: "topbar",

  mixins: [generalMixin],

  data() {
    return {};
  },

  watch: {},

  computed: {
    ...mapGetters("auth", ["isLogged", "user"]),
    // ...mapGetters({
    //   customerDetails: "customer/customer",
    // }),
  },

  methods: {
    logout() {
      const logOut = this.$store.dispatch("auth/logout");
      if (logOut) {
        window.location.reload();
      }
    },
  },
};
</script>

<style scoped></style>
